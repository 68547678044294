import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';

const CookiebotContext = createContext();

export const useCookiebot = () => useContext(CookiebotContext);

// Provider component that sets up the context
export const CookiebotProvider = ({ children }) => {
  const [cookieConsent, setCookieConsent] = useState({});

  // Calculate various values based on consent states

  const isIntercomAllowed = useMemo(() => {
    return cookieConsent?.preferences ?? false;
  }, [cookieConsent?.preferences]);

  const isHubspotAllowed = useMemo(() => {
    return cookieConsent?.statistics ?? false;
  }, [cookieConsent?.statistics]);

  const isRedditPixelAllowed = useMemo(() => {
    return cookieConsent?.marketing ?? false;
  }, [cookieConsent?.marketing]);

  const isGoogleAnalyticsAllowed = useMemo(() => {
    return cookieConsent?.statistics ?? false;
  }, [cookieConsent?.statistics]);

  useEffect(() => {
    const handleConsent = () => {
      setCookieConsent({ ...window.Cookiebot?.consent });
    };

    if (window.Cookiebot) {
      handleConsent();
    }

    window.addEventListener('CookiebotOnConsentReady', handleConsent);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', handleConsent);
    };
  }, []);

  return (
    <CookiebotContext.Provider value={{
      cookieConsent,
      isIntercomAllowed,
      isHubspotAllowed,
      isRedditPixelAllowed,
      isGoogleAnalyticsAllowed,
    }}>
      {children}
    </CookiebotContext.Provider>
  );
};
