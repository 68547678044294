import React, { useState, useRef, useEffect, useCallback } from 'react';
import './Signup.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser,
    faEnvelopeCircleCheck,
    faCheckCircle,
    faDollarSign,
    faChevronLeft,
    faChevronRight,
    faCheckSquare
} from '@fortawesome/free-solid-svg-icons';
import {
    faSquare
} from '@fortawesome/free-regular-svg-icons';
import { useForm, Controller } from 'react-hook-form';
import StepNavigation from '../../common/components/StepNavigation';
import StateSelector from '../../common/components/StateSelector';
import CountrySelector from '../../common/components/CountrySelector';
import FormSectionHeader from '../../common/components/FormSectionHeader';
import SportSelector from '../../common/components/SportSelector';
import Tooltip from '../../common/components/Tooltip';
import BlockOptions from '../../common/components/BlockOptions';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import Confetti from '../../common/components/Confetti';
import DOBSelector from '../../common/components/DOBSelector/DOBSelector'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import cx from 'classnames';
import {
    useUTMParams,
    useAmbassadorParams,
    sellerOptions,
    volumeOptions,
    referralOptions,
    getAuthRedirectURI,
    isServerRender
} from '../../utils';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import ReCAPTCHA from 'react-google-recaptcha';
import auth0 from 'auth0-js';
import * as Sentry from "@sentry/browser";
import LogIn from '../../common/components/LogIn';
import Helmet from 'react-helmet';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { isValidPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input/max';
import { useCookiebot } from '../../utils/CookiebotContext';

function Signup({ isMobile }) {
    const recaptchaRef = useRef();
    
    const {
        register,
        control,
        handleSubmit,
        formState: {
            errors
        },
        reset,
        clearErrors,
        setError,
        setValue,
        getValues
    } = useForm({
        defaultValues: {
            country: { value: 'US', label: 'United States' }
        }
    });

    const location = useLocation();

    const { utmSource, utmMedium, utmCampaign } = useUTMParams(location);
    const { mbsySource, mbsyCampaign, mbsyexp, shortcode } = useAmbassadorParams(location);
    const navigate = useNavigate();

    const [steps, setSteps] = useState([
        { id: 1, name: 'Registration', icon: faUser, completed: false, visible: true },
        { id: 2, name: 'Ticketing', icon: null, completed: false, visible: false },
        { id: 3, name: 'dynamic', icon: null, completed: false, visible: false, final: !!utmSource || !!shortcode },
        { id: 4, name: 'Referral', icon: null, completed: false, visible: false, final: !utmSource && !shortcode },
        { id: 5, name: 'Verification', icon: faEnvelopeCircleCheck, completed: false, visible: true },
        { id: 6, name: 'Activation', icon: faCheckCircle, completed: false, visible: true },
        { id: 7, name: 'Earn', icon: faDollarSign, completed: false, visible: true },
    ]);

    const { setTrackEvent, setIdentity } = useTrackingCode();

    const [currentStep, setCurrentStep] = useState(1);
    const [submitting, setSubmitting] = useState(false);
    const [serverError, setServerError] = useState('');
    const [selectedCountry, setSelectedCountry] = useState({ value: 'US', label: 'United States' });
    const [selectorStates, setSelectorStates] = useState({
        country: {
            focused: false,
            filled: true
        },
        state: {
            focused: false,
            filled: false
        }
    });
    const [sellerTypeNextStep, setSellerTypeNextStep] = useState(null);
    const [phoneHasValue, setPhoneHasValue] = useState(false);
    const [phoneIsFocused, setPhoneIsFocused] = useState(false);

    const {
        isHubspotAllowed,
        isRedditPixelAllowed,
        isGoogleAnalyticsAllowed,
    } = useCookiebot();

    const checkPhoneValue = (value) => {
        if (value) {
            setPhoneHasValue(true);
        } else {
            setPhoneHasValue(false);
        }
    };

    const handleBack = (e) => {
        e.preventDefault();
        if (currentStep === 1) {
            navigate('/');
        } else {
            let increment = ((currentStep === 4) && !sellerTypeNextStep) ? 2 : 1;
            setCurrentStep(currentStep - increment);
        }

        if (sellerTypeNextStep === 'season') {
            setValue('league', null);
            setValue('team', null);
        }

        document?.documentElement.scrollTo(0, 0);
    };

    const updateSteps = (increment = 1) => {
        const updatedSteps = steps.map(step => {
            if (step.id === currentStep) {
                return { ...step, completed: true };
            }
            return step;
        })
        setSteps(updatedSteps);
        setCurrentStep(currentStep + increment);
    };

    const handleSellerTypeSelected = (type) => {
        const shouldSetType = ['large', 'season'].includes(type);
        let increment = shouldSetType ? 1 : !!utmSource || shortcode ? 3 : 2;
        setSellerTypeNextStep(shouldSetType ? type : null);
        updateSteps(increment);
        if (increment === 3) {
            handleSignup();
        }
    };

    const handleVolumeSelected = (volume) => {
        handleNextStep();
    }

    const handleContinue = async (data) => {
        if (currentStep < steps.length && !Object.keys(errors).length) {
            if (currentStep === 2) {
                handleSellerTypeSelected(sellerTypeNextStep)
            } else if (currentStep < 5) {
                handleNextStep();
            } else {
                handleSignup();
            }
        }
    };

    const memoizedGetAuthRedirectURI = useCallback(() => {
        return getAuthRedirectURI(location);
    }, [location]);

    const webauth = new auth0.WebAuth({
        audience: 'https://lysted.com/api',
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
        redirectUri: memoizedGetAuthRedirectURI(),
        responseType: 'code',
        leeway: 60,
        scope: 'openid profile email'
    });

    const handleSignup = async () => {
        try {
            setSubmitting(true);
            const data = getValues();
            const volume = data.seller_type === 'large' ? data.sales_volume : null;
            const source = shortcode ? `ambassador${utmSource ? `-${utmSource}` : ''}` : utmSource || data.referrer || 'NOT_SET'
            const team = data.seller_type === 'season' ? `${data.league?.sport || 'other'}|${data.league?.league || 'other'}|${data.team || 'other'}` : null
            
            if (!data.dobYear || !data.dobMonth || !data.dobDay) {
                throw new Error('Please select a valid date of birth');
            }

            // Pad both month and day values to get a format of YYYY-MM-DD
            const dobString = `${data.dobYear}-${data.dobMonth.toString().padStart(2, '0')}-${data.dobDay.toString().padStart(2, '0')}`;
            
            const payload = {
                source,
                utm: {
                    source,
                    ...(utmMedium && { medium: utmMedium }),
                    ...(utmCampaign && { campaign: utmCampaign }),
                    ...(shortcode && { shortcode }),
                    ...(shortcode && {
                        mbsy: {
                            source: mbsySource || 'NOT_SET',
                            campaign: mbsyCampaign || 'NOT_SET',
                            exp: mbsyexp || 'NOT_SET'
                        }
                    })
                },
                first: data.first,
                last: data.last,
                dob: dobString,
                consentToVerify: data.agreement,
                email: data.email,
                phone: formatPhoneNumberIntl(data.phone),
                ...(data.company && {company: data.company}),
                address: {
                    address_1: data.address_1,
                    ...(data.address_2 && { address_2: data.address_2 }),
                    city: data.city,
                    state: data.state.value,
                    postal_code: data.postal_code,
                    country: data.country.value,
                },
                seller_type: data.seller_type,
                ...(volume && { volume }),
                ...(team && { team })
            }
            const token = await recaptchaRef.current.executeAsync();
            payload.token = token;
            
            // API request
            const response = await axios.post(`${process.env.REACT_APP_SIGNUP_URI}/signup`, payload);
            
            if (response.status === 201) {
                setServerError(null);
                try {
                    // HubSpot Contact generation
                    if (isHubspotAllowed) {
                        setIdentity(data.email, data);
                        setTrackEvent({
                            eventId: `${process.env.NODE_ENV !== 'production' ? 'TEST_' : ''}LYSTED_SIGNUP`,
                            value: 'COMPLETED'
                        });
                    }
                    // Reddit pixel event
                    if (isRedditPixelAllowed && !isServerRender && !!window.rdt) {
                        window.rdt('track', `SignUp${process.env.NODE_ENV !== 'production' ? '_TEST' : ''}`);
                    }
                    // GA Conversion event
                    if (isGoogleAnalyticsAllowed && !isServerRender && !!window.dataLayer) {
                        // @note gtag is not set when using GTM to laod GA so we must use dataLayer
                        // @note window.dataLayer existing doesn't mean GA is loaded
                        window.dataLayer.push({
                            'event': 'conversion',
                            'send_to': 'AW-11164999022/7-8wCJHurOUYEO668csp'
                        });
                    }
                } catch (e) {
                    Sentry.captureMessage('3P_FAILURE', {
                        extra: {
                            error: e?.toString(),
                        }
                    });
                }
            } else if (response.status === 204) {
                // Account exists, push to Auth
                webauth.authorize();
            } else {
                setServerError('There was an unknown error processing your request. Please refresh and try again.');
            }
        } catch (error) {
            let errorMessage = error?.response
                ? error.response.data?.message || error.response.data
                : 'An error occurred. Please try again later.';

            if (typeof errorMessage === 'object' && errorMessage !== null) {
                errorMessage = 'An error occurred. Please try again later.'
            }

            if (error?.response?.status !== 409) {
                Sentry.captureMessage('handleSignup error', {
                    extra: {
                        data: error?.response?.data,
                        status: error?.response?.status,
                        error,
                        errorMessage,
                    }
                });
            }
            
            setServerError(errorMessage);
        }
        setSubmitting(false);
    }

    const handleModalUnmount = useCallback(() => {
        reset();
    }, [reset]);

    const handleLeagueSelected = (league) => {
        setValue('team', null);
        if (league?.sport === 'other') {
            setValue('team', 'other');
            updateSteps();
        }
    };

    const handleTeamSelected = (team) => {
        handleNextStep();
    };

    const handleReferrerSelected = (referrer) => {
        handleNextStep();
    }

    const handleNextStep = () => {
        const nextStep = (currentStep + 1)
        const skipSource = ((nextStep === 4) && (!!utmSource || !!shortcode));
        const increment = skipSource ? 2 : 1;
        updateSteps(increment);
        if ((increment === 2) || (nextStep === 5)) {
            handleSignup();
        }
        document?.documentElement.scrollTo(0, 0);
    }

    const getHeaderText = () => {
        if (!!serverError) {
            return 'Whoops! There was an error';
        }
        if (currentStep === 5 && !submitting) {
            return `🎉 Great! We've received your request! 🎉`;
        }

        return 'Sign up to get Lysted';
    }

    useEffect(() => {
        // Reset form on unmount
        return () => {
            handleModalUnmount();
        };
    }, [handleModalUnmount]);

    return (
        <>
            <Helmet>
                <title>Lysted | Sign up to get Lysted</title>
                <link rel="canonical" href="https://lysted.com/signup" />
            </Helmet>
            <div className={cx('modal-content', {
                'mobile': isMobile
            })}>
                <div className={cx('modal-body', {
                    'mobile': isMobile
                })}>
                    <h1>{getHeaderText()}</h1>
                    <div className={cx('step-container', {
                        'mobile': isMobile
                    })}>
                        <StepNavigation
                            steps={steps}
                            currentStep={currentStep}
                            onClose={() => navigate('/')}
                            isMobile={isMobile}
                        />
                        <form onSubmit={handleSubmit(handleContinue)} className={cx({
                            'mobile': isMobile
                        })}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            />
                            <div className={cx('form-container', {
                                'mobile': isMobile
                            })}>
                                {currentStep === 1 && (
                                    <>
                                        <FormSectionHeader className="section-divider-custom" title="Contact information" isMobile={isMobile} />
                                        <div className="form-field">
                                            <input id="company" name="company" placeholder="Company name (optional)" {...register('company', { required: false })} />
                                            <label htmlFor="company">{"Company name (optional)"}</label>
                                        </div>
                                        <div className="form-group">
                                            <div className={cx('form-field', {
                                                'error': errors.first
                                            })}>
                                                <input id="first" name="first" placeholder="First name" {...register('first', { required: true })} />
                                                <label htmlFor="first">First name</label>
                                                {errors.first && <span className="error">Required</span>}
                                            </div>
                                            <div className={cx('form-field', {
                                                'error': errors.last
                                            })}>
                                                <input id="last" name="last" placeholder="Last name" {...register('last', { required: true })} />
                                                <label htmlFor="last">Last name</label>
                                                {errors.last && <span className="error">Required</span>}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className={cx('form-field', {
                                                'error': errors.email
                                            })}>
                                                <input
                                                    id="email"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    {...register('email', {
                                                        required: 'Required',
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: 'Invalid email address'
                                                        }
                                                    })}
                                                />
                                                <label htmlFor="email">Email</label>
                                                {errors.email && <span className="error">{errors.email.message}</span>}
                                            </div>
                                            <div className={cx('form-field', {
                                                'error': errors.phone
                                            })}>
                                                <Controller
                                                    name="phone"
                                                    control={control}
                                                    rules={{
                                                        required: 'Required',
                                                        validate: (value) => isValidPhoneNumber(value) || "Invalid phone number"
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <PhoneInput
                                                            value={value}
                                                            onChange={(v) => {
                                                                onChange(v);
                                                                setValue('phone', v);
                                                                checkPhoneValue(v);
                                                                if (v) {
                                                                    clearErrors('phone');
                                                                }
                                                            }}
                                                            defaultCountry="US"
                                                            id="phone"
                                                            placeholder="Phone number"
                                                            name="phone"
                                                            className={cx({
                                                                'PhoneInput--reallyFocused': phoneIsFocused
                                                            })}
                                                            onFocus={() => setPhoneIsFocused(true)}
                                                            onBlur={() => {
                                                                setPhoneIsFocused(false)
                                                                if (value && !isValidPhoneNumber(value)) {
                                                                    setError('phone', {
                                                                        type: "validate",
                                                                        message: 'Invalid phone number',
                                                                    });
                                                                } else {
                                                                    clearErrors('phone');
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <label
                                                    className={cx({
                                                    'filled': phoneHasValue,
                                                    'focused': phoneIsFocused && phoneHasValue
                                                    })}
                                                    htmlFor="phone"
                                                >
                                                    Phone number
                                                </label>
                                                {errors.phone && <span className="error">{errors.phone.message}</span>}
                                            </div>
                                        </div>
                                        <FormSectionHeader
                                            title="Mailing address"
                                            rightNode={(
                                                <Tooltip
                                                    id="sign-up-form-address-explainer"
                                                    label={'Why do we collect this?'}
                                                >
                                                    <p>
                                                        {`We must collect your mailing address in order to process financial transactions such as ticket sales and payouts. Learn more by reading our `}
                                                        <Link
                                                            to={'https://help.lysted.com/en/articles/8809352-terms-of-service'}
                                                            target={'_blank'}
                                                            rel={'noopener noreferrer'}
                                                        >terms of service</Link>{'.'}
                                                    </p>
                                                </Tooltip>
                                            )}
                                            isMobile={isMobile}
                                        />
                                        <div className="form-field">
                                            <Controller
                                                name="country"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <CountrySelector
                                                        value={field.value}
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption);
                                                            setSelectedCountry(selectedOption);
                                                            clearErrors('phone');
                                                            setValue('state', null);
                                                            setSelectorStates({
                                                                country: {
                                                                    focused: selectorStates.country.focused,
                                                                    filled: !!selectedOption
                                                                },
                                                                state: {
                                                                    focused: selectorStates.state.focused,
                                                                    filled: false
                                                                }
                                                            });
                                                        }}
                                                        onFocus={() => {
                                                            setSelectorStates({
                                                                country: {
                                                                    focused: true,
                                                                    filled: selectorStates.country.filled
                                                                },
                                                                state: {
                                                                    focused: false,
                                                                    filled: selectorStates.state.filled
                                                                }
                                                            });
                                                        }}
                                                        onBlur={() => {
                                                            setSelectorStates({
                                                                country: {
                                                                    focused: false,
                                                                    filled: selectorStates.country.filled
                                                                },
                                                                state: {
                                                                    focused: selectorStates.state.focused,
                                                                    filled: selectorStates.state.filled
                                                                }
                                                            });
                                                        }}
                                                        className={cx('form-field', 'select', {
                                                            'error': errors.country
                                                        })}
                                                    />
                                                )}
                                            />
                                            <label
                                                className={cx({
                                                    'filled': selectorStates.country.filled,
                                                    'focused': selectorStates.country.focused && selectorStates.country.filled
                                                })}
                                                htmlFor="country"
                                            >
                                                Country
                                            </label>
                                            {errors.country && <span className="error">Please select a country</span>}
                                        </div>
                                        <div className={cx('form-field', {
                                            'error': errors.address_1
                                        })}>
                                            <input id="address_1" name="address_1" placeholder="Street and number, P.O. box, c/o." {...register('address_1', { required: true })} />
                                            <label htmlFor="address_1">Street address</label>
                                            {errors.address_1 && <span className="error">Required</span>}
                                        </div>
                                        <div className="form-field">
                                            <input id="address_2" name="address_2" placeholder="Apartment, suite, unit, building, floor, etc." {...register('address_2', { required: false })} />
                                        </div>
                                        <div className="form-group">
                                            <div className={cx('form-field', {
                                                'error': errors.city
                                            })}>
                                                <input id="city" name="city" placeholder="City" {...register('city', { required: true })} />
                                                <label htmlFor="city">City</label>
                                                {errors.city && <span className="error">Required</span>}
                                            </div>
                                            <div className="form-field">
                                                <Controller
                                                    name="state"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <StateSelector
                                                            country={selectedCountry}
                                                            value={field.value}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                setSelectorStates({
                                                                    country: {
                                                                        focused: selectorStates.country.focused,
                                                                        filled: selectorStates.country.filled
                                                                    },
                                                                    state: {
                                                                        focused: selectorStates.state.focused,
                                                                        filled: !!selectedOption
                                                                    }
                                                                });
                                                            }}
                                                            onFocus={() => {
                                                                setSelectorStates({
                                                                    country: {
                                                                        focused: false,
                                                                        filled: selectorStates.country.filled
                                                                    },
                                                                    state: {
                                                                        focused: true,
                                                                        filled: selectorStates.state.filled
                                                                    }
                                                                });
                                                            }}
                                                            onBlur={() => {
                                                                setSelectorStates({
                                                                    country: {
                                                                        focused: selectorStates.country.focused,
                                                                        filled: selectorStates.country.filled
                                                                    },
                                                                    state: {
                                                                        focused: false,
                                                                        filled: selectorStates.state.filled
                                                                    }
                                                                });
                                                            }}
                                                            className={cx('form-field', 'select', 'mid', {
                                                                'error': errors.state
                                                            })}
                                                        />
                                                    )}
                                                />
                                                <label
                                                    htmlFor="state"
                                                    className={cx({
                                                        'filled': selectorStates.state.filled,
                                                        'focused': selectorStates.state.focused && selectorStates.state.filled
                                                    })}
                                                >
                                                    {"State / Province / Region"}
                                                </label>
                                                {errors.state && <span className="error">Required</span>}
                                            </div>
                                            <div className={cx('form-field', {
                                                'error': errors.postal_code
                                            })}>
                                                <input id="postal_code" name="postal_code" placeholder="Zip / Postal Code" {...register('postal_code', { required: true })} />

                                                <label htmlFor="postal_code">Zip / Postal Code</label>
                                                {errors.postal_code && <span className="error">Required</span>}
                                            </div>
                                        </div>

                                        <DOBSelector isMobile={isMobile} control={control} setValue={setValue} getValues={getValues} errors={errors} />

                                        <FormSectionHeader
                                            title="Terms"
                                            isMobile={isMobile}
                                        />
                                        <div className="form-group">
                                            <div className={cx('form-field', {
                                                'error': errors.agreement
                                            })}>
                                                <label className="checkbox-label">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        {...register('agreement', { required: true })}
                                                    />
                                                    <span className="checkbox-custom">
                                                        <FontAwesomeIcon icon={faCheckSquare} className="checked" />
                                                        <FontAwesomeIcon icon={faSquare} className="unchecked" />
                                                    </span>
                                                    I agree to Lysted's <Link to="https://help.lysted.com/en/articles/8809352-terms-of-service" target={'_blank'} rel={'noopener noreferrer'}>Terms of Service</Link>
                                                </label>
                                                {errors.agreement && <span className="error">You must agree to the terms of service</span>}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className={cx('form-field')}>
                                                <div className={'recaptcha-privacy-terms'}>
                                                    This form is protected by reCAPTCHA. <Link to="https://policies.google.com/privacy" target={'_blank'} rel={'noopener noreferrer'}>Google's Privacy Policy</Link> and <Link to="https://policies.google.com/terms" target={'_blank'} rel={'noopener noreferrer'}>Google's Terms of Service</Link> apply.
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {currentStep === 2 && (
                                    <>
                                        <FormSectionHeader
                                            className="section-divider-custom"
                                            title={isMobile ? 'Sellery category?' : 'What is your ticket seller category?'}
                                            rightNode={(
                                                <Tooltip
                                                    id="sign-up-form-use-case-explainer"
                                                    label={'Why do we collect this?'}
                                                    place='bottom'
                                                >
                                                    <span>
                                                        {`By indicating your ticket seller category, we can provide you better training and support and enhance your user experience on Lysted.`}
                                                    </span>
                                                </Tooltip>
                                            )}
                                            isMobile={isMobile}
                                        />
                                        <BlockOptions
                                            name="seller_type"
                                            options={sellerOptions}
                                            control={control}
                                            errors={errors}
                                            onOptionSelected={handleSellerTypeSelected}
                                            isMobile={isMobile}
                                        />
                                    </>
                                )}
                                {currentStep === 3 && (
                                    <>
                                        {sellerTypeNextStep === 'large' ? (
                                            <>
                                                <FormSectionHeader
                                                    className="section-divider-custom"
                                                    title={isMobile ? 'Annual sales volume?' : 'What is your approximate annual sales volume?'}
                                                    rightNode={(
                                                        <Tooltip
                                                            id="sign-up-form-use-case-explainer"
                                                            label={'Why do we collect this?'}
                                                            place='bottom'
                                                        >
                                                            <span>
                                                                {`By indicating your approximate yearly sales volume, we can provide you better training and support and enhance your user experience on Lysted.`}
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                                    isMobile={isMobile}
                                                />
                                                <BlockOptions
                                                    name="sales_volume"
                                                    options={volumeOptions}
                                                    control={control}
                                                    errors={errors}
                                                    onOptionSelected={handleVolumeSelected}
                                                    isMobile={isMobile}
                                                />
                                            </>
                                        ) : (
                                            <SportSelector
                                                control={control}
                                                errors={errors}
                                                onLeagueSelected={handleLeagueSelected}
                                                onTeamSelected={handleTeamSelected}
                                                isMobile={isMobile}
                                            />
                                        )}
                                    </>
                                )}
                                {currentStep === 4 && (
                                    <>
                                        <FormSectionHeader
                                            className="section-divider-custom"
                                            title="How did you hear about us?"
                                            isMobile={isMobile}
                                        />
                                        <BlockOptions
                                            name="referrer"
                                            options={referralOptions}
                                            control={control}
                                            errors={errors}
                                            onOptionSelected={handleReferrerSelected}
                                            isMobile={isMobile}
                                        />
                                    </>
                                )}
                                {currentStep === 5 && (
                                    <>
                                        {submitting ? (
                                            <LoadingSpinner />
                                        ) : !serverError ? (
                                            <>
                                                <Confetti />
                                                <div className="form-field completed">
                                                    <h2>Please check your email</h2>
                                                    <span className="next-steps">
                                                        In a few moments an email will be sent to <strong>{getValues('email')}</strong> with a link to verify your email and create a password.
                                                    </span>
                                                    <div
                                                        onClick={() => navigate('/')}
                                                        className="btn sign-up-complete"
                                                    >Return to Lysted</div>
                                                    <span className="next-steps-spam">
                                                        {`Didn't receive the email? Please check your Spam folder. If you don't find it there, contact support.`}
                                                    </span>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="form-field completed">
                                                <h2>Unable to complete Sign up</h2>
                                                <span className="next-steps error">
                                                    {serverError}
                                                </span>
                                                <div
                                                    onClick={() => navigate('/')}
                                                    className="btn sign-up-complete"
                                                >Return to Lysted</div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            {currentStep < 5 && (
                                <div className={cx('button-group', {
                                    'mobile': isMobile
                                })}>
                                    <button type="button" className="back-button" onClick={handleBack}>
                                        {currentStep === 1 ? 'Cancel' : (
                                            <>
                                                <FontAwesomeIcon size="lg" icon={faChevronLeft} className="chevron-icon-left" />
                                                {'Back'}
                                            </>
                                        )}
                                    </button>
                                    <button type="submit">
                                        {steps[currentStep - 1]?.final ? 'Complete' : 'Continue'}
                                        <FontAwesomeIcon size="lg" icon={faChevronRight} className="chevron-icon" />
                                    </button>
                                </div>
                            )}
                        </form>
                    </div>
                    <div className={'existing-account'}>
                        Already have an account? <LogIn className={'link'} location={location} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Signup;